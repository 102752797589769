<div class="loading" *ngIf="!ready && !error">
  <mat-spinner>
  </mat-spinner>
</div>
<div fxLayout="row" fxLayoutAlign="space-between" class="navbar" *ngIf="ready">
  <img src="assets/images/guide/ThriveLogo.png" alt="Thrive logo" width="203" height="80">
  <img *ngIf="clientLogo" [src]="clientLogo" alt="{{config.serviceName}} logo" width="auto" height="80">
</div>
<div class="main" *ngIf="ready">
  <h1>{{'InstallThrive' | translate}}</h1>
  <p [innerText]="introduction"></p><br>
  <div gdColumns="repeat(auto-fit, minmax(300px, 1fr)" gdGap="4rem">
    <div class="step-container" *ngFor="let image of albums; let i=index">
      <img class="instructionImage" [src]="image.thumb" (click)="open(i)" alt="Instruction screenshot {{i+1}}"/>
      <div class="caption" [innerHTML]="image.caption"></div>
    </div>
  </div>
  <div class="buttonArea">
    <app-thrive-button aria-label="Download Thrive" fxLayoutAlign="start" color="primary" (click)="openAppStore()">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
        <mat-icon>get_app</mat-icon>
        <span>{{'GetApp' | translate}}</span>
      </div>
    </app-thrive-button>
  </div>
</div>
